html,
body {
  margin: 0px;
  padding: 0px;
}
.App {
  width: 100%;
  height: 100%;
}

.attribution {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  font: 12px Helvetica Neue, Arial, Helvetica, sans-serif;
  line-height: 12px;
  padding: 4px;
  z-index: 9;
}
